
import { defineComponent, reactive, ref, onMounted, } from "vue";
import {
  getViews,
  saveViews,
  getVehiclePriceDetails,
} from "@/API/systemVehicle/systemVehicleIndex";
import useFetchCommon from "@/hooks/useFetchCommon";
import Pagination from "@/components/Pagination.vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import useTableHeight from "@/hooks/useTableHeight";
import { PriceDetailContent } from './types'
interface VapiType {
  brand: string;
  typeClass: string;
  bM6: string;

  materialNo: string;
  modelName: string;
  make: string;
  modelYear: string;
}
export default defineComponent({
  name: "DealerView",
  components: {
      Pagination
  },
  setup() {
    const route = useRoute();
    const { back } = useRouter();
    const dataSource = ref<VapiType[]>([]);
    const { tableHeight } = useTableHeight();
 
    const columns = [
      {
        title: "Operate",
        dataIndex: "operateDate",
        key: "operateDate",
        width: 150,
      },
      {
        title: "Effective Date",
        dataIndex: "effectiveDate",
        key: "effectiveDate",
        width: 150,
      },
      {
        title: "Ct Absolute",
        dataIndex: "ctAbsolute",
        key: "ctAbsolute",
        width: 110,
        slots: { customRender: 'msrp' },
      },
      {
        title: "Margin Basis",
        dataIndex: "marginBasis",
        key: "marginBasis",
        width: 110,
        slots: { customRender: 'msrp' },
      },
      {
        title: "MSRP China Standard w/ o VAT",
        dataIndex: "msrpChinaStandardWithoutVat",
        key: "msrpChinaStandardWithVat",
        slots: { customRender: 'msrp' },
        width: 220,
      },
      {
        title: "MSRP China Standard w VAT",
        dataIndex: "msrpChinaStandardWithVat",
        key: "msrpChinaStandardWithoutVat",
        slots: { customRender: 'msrp' },
        width: 200,
      },
      {
        title: "Fixed Margin",
        dataIndex: "fixedMargin",
        key: "fixedMargin",
        width: 100,
      },
      {
        title: "Fix Margin",
        dataIndex: "fixMargin",
        key: "fixMargin",
        width: 100,
        slots: { customRender: 'msrp' },
      },
      {
        title: "VAT Rate",
        dataIndex: "vatRate",
        key: "vatRate",
        width: 80,
      },
      {
        title: "Extra Charge w VAT",
        dataIndex: "extraCharge_w_vat",
        key: "extraCharge_w_vat",
        width: 140,
      },
    ];
    const vehicleContent = reactive({
      brand: "",
      nstModelNameCn: "",
      Make: "",
      typeClassEn: "",
      typeClassNameCn: "",
      "modelName_CN": "",
      powerTrain: "",
      marketLaunchDate: "",
      isActive: "",
      materialId: "",
      nstModelNameEn: "",
      nstGroup: "",
      nstGroupName: "",
      bm6: "",
      bm6Txt: "",
      status: "",
      msrpChinaStandardWithoutVat: "",
      msrpChinaStandardWithVat: "",
      ctAbsolute: "",
      marginBasis: "",
      fixedMargin: "",
      fixMargin: "",
      vatRate: "",
      "extraCharge_w_vat": "",
      effectiveDate: "",
    });
    const vehicleInfo = reactive([
      [
        {
          name: "Brand",
          key: "brand",
          type: "text",
        },
        {
          name: "Model Name_CN",
          key: "nstModelNameCn",
          type: "input",
        },
        {
          name: "Make",
          key: "make",
          type: "text",
        },
        {
          name: "Type Class",
          key: "typeClassEn",
          type: "text",
        },
        {
          name: "Type Class CN",
          key: "typeClassNameCn",
          type: "text",
        },
        {
          name: "Model Year",
          key: "modelYear",
          type: "text",
        },
        {
          name: "Power Train",
          key: "powerTrain",
          type: "text",
        },
        {
          name: "Market Launch Date",
          key: "marketLaunchDate",
          type: "text",
        },
        {
          name: "Is Active",
          key: "isActive",
          type: "radio",
        },
      ],
      [
        {
          name: "Material No",
          key: "materialId",
          type: "text",
        },
        {
          name: "Model Name_EN",
          key: "nstModelNameEn",
          type: "text",
        },
        {
          name: "Nst Group",
          key: "nstGroup",
          type: "text",
        },
        {
          name: "Nst Group Name",
          key: "nstGroupName",
          type: "text",
        },
        {
          name: "BM6",
          key: "bm6",
          type: "text",
        },
        {
          name: "BM6 TXT",
          key: "bm6Txt",
          type: "text",
        },
        {
          name: "Launch Status",
          key: "statusName",
          type: "text",
        },
      ],
      [
        {
          name: "MSRP China Standard w/ o VAT",
          key: "msrpChinaStandardWithoutVat",
          type: "msrp",
        },
        {
          name: "MSRP China Standard w VAT",
          key: "msrpChinaStandardWithVat",
          type: "msrp",
        },
        {
          name: "Ct Absolute",
          key: "ctAbsolute",
          type: "msrp",
        },
        {
          name: "Margin Basis:",
          key: "marginBasis",
          type: "msrp",
        },
        {
          name: "Fixed Margin",
          key: "fixedMargin",
          type: "text",
        },
        {
          name: "Fix Margin",
          key: "fixMargin",
          type: "msrp",
        },
        {
          name: "VAT Rate",
          key: "vatRate",
          type: "text",
        },
        {
          name: "Extra Charge w VAT",
          key: "extraCharge_w_vat",
          type: "text",
        },
        {
          name: "Effective date",
          key: "effectiveDate",
          type: "text",
        },
      ],
    ]);
    //获取页面数据
    const getViewsIndex = () => {
      const params = {};
      getViews(params, String(route.query.vehicleModelInfoId)).then((res) => {
        Object.assign(vehicleContent, res);
      });
    };

    // 获取price detail
    const priceDetailParams = {
      vehicleId: route.query.vehicleModelInfoId

    }
    const { data: priceDetailData, pagination, refreshFetch } = useFetchCommon<PriceDetailContent[]>({ params: priceDetailParams, initData: [], fetchApi: getVehiclePriceDetails, isPagination: true })
    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
        pagination.page = page;
        refreshFetch(false);
    }

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
        pagination.size = pageSize;
        Object.assign(pagination, { page: current, size: pageSize });
        refreshFetch(false);
    }
    //保存
    const saveEdit = () => {
      const params = {
        ...vehicleContent,
      };
      saveViews(params).then((res) => {
        message.success("update Vehicle info successfully");
        setTimeout(() => {
          back();
        }, 1000);
      });
    };
    
    const onChange = async (e: any) => {
      vehicleContent.isActive = e.target.checked === true ? "1" : "0";
    };

    onMounted(() => {
      getViewsIndex();
    });

    return {
      columns,
      tableHeight,
      vehicleInfo,
      vehicleContent,
      dataSource,
      route,
      onChange,
      saveEdit,
      priceDetailData,
      pagination,
      handlerPaginationChange,
      handlerShowSizeChange
    };
  },
});
