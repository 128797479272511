import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cee45ef6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-container d-f justify-e" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_pagination, {
      total: _ctx.total,
      "show-size-changer": "",
      "show-quick-jumper": "",
      current: _ctx.current,
      defaultPageSize: _ctx.defaultPageSize,
      pageSize: _ctx.pageSize,
      pageSizeOptions: _ctx.pageSizeOptions,
      "show-total": total => `Total ${total} items`,
      onChange: _ctx.handlerChange,
      onShowSizeChange: _ctx.handlerShowSizeChange
    }, null, 8, ["total", "current", "defaultPageSize", "pageSize", "pageSizeOptions", "show-total", "onChange", "onShowSizeChange"])
  ]))
}