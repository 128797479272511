/*
 * @Author: guowu
 * @Date: 2021-07-28 13:56:38
 * @FilePath: \oasis-frontend\src\API\systemVehicle\systemVehicleIndex.ts
 */
import { fetchGet, fetchPut, fetchPost } from "../index";
import { Entity } from "@/views/System/VehicleManagement/types";
import axios from "axios";

//获取VapList列表
export const getVapiList = (params = {}): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/", { params });
};
//获取BrandsArrayList列表 saleapi
export const getBrandsArrayList = (params = {}): Promise<any> => {
  return fetchGet("/saleapi/vehicleMaster/brands", { params });
};
//获取MakerrayList列表 saleapi
export const getMakeArrayList = (params = {}): Promise<any> => {
  return fetchGet("/saleapi/vehicleMaster/makes", { params });
};
//获取PowertrainsList列表 saleapi
export const getPowerTrainArrayList = (params = {}): Promise<any> => {
  return fetchGet("/saleapi/vehicleMaster/powerTrains", { params });
};

//获取BrandsArrayList列表
export const getBrandsArrayListInfo = (params = {}): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/brands", { params });
};
//获取MakerrayList列表
export const getMakeArrayListInfo = (params = {}): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/makes", { params });
};
//获取PowertrainsList列表
export const getPowerTrainArrayListInfo = (params = {}): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/powertrains", { params });
};

//获取statusList列表
export const getStatusArrayList = (params = {}): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/status", { params });
};
//获取view数据
export const getViews = (params = {}, ids: string): Promise<any> => {
  return fetchGet("/vapi/vehicleModelInfo/" + ids, { params });
};
//保存view数据
export const saveViews = (params = {}): Promise<any> => {
  return fetchPut("/vapi/vehicleModelInfo", params);
};

export const getVehiclePriceDetails = (params: {}) => {
  return fetchGet("/vapi/vehicleModelInfo/vehiclePriceDetails", { params });
};

// Vehicle Management
// 获取车辆列表
export const getVehicleiList = (params = {}, pageParams: any): Promise<any> => {
  return fetchPost(
    `/saleapi/vehicleMaster?page=${pageParams.page}&size=${pageParams.size}`,
    params
  );
};

export const exportVANData = (params = {}): Promise<any> => {
  return axios.post(`/saleapi/vehicleMaster/export `, params, {
    responseType: "blob",
  });
};
//获取view数据
export const getVehicleViews = (params = {}): Promise<any> => {
  return fetchGet("/saleapi/vehicleMaster/view", { params });
};

// 获取entity下拉数据
export const getEntityName = (params = {}): Promise<any> => {
  return fetchGet("/saleapi/import/userBu/special", params);
};

// brand make powerTrain 联动
export const getFilterListSearch = (params = {}): Promise<any> => {
  return fetchPost("/vapi/vehicleModelInfo/filterListSearch", params);
};
