import { onMounted, reactive, ref } from "vue";

interface Pagination {
    size: number; //每页多少条
    page: number; //当前页数
    total: number; //总条数
}

interface Params {
    [key: string]: any;
}

/**
 * 所有的返回的分页都是这个数据格式
 * @param parameter
 * @param parameter params ajax 查询参数
 * @param parameter initData 初始化数据
 * @param parameter fetchApi ajax请求api
 * @param parameter resTransform response 处理函数
 * @param parameter isPagination 是否带分页查询
 *
 */
function useFetchCommon<T>(parameter: { params?: Params; initData: T; fetchApi: Function; resTransform?: Function; isPagination?: boolean }) {
    const pagination = reactive<Pagination>({
        size: parameter.params?.sizi ?? 10,
        page: 1,
        total: 0,
    })

    const data = ref<T>(parameter.initData);

    /**
     *
     * @param resetPage 是否重置分页page 1
     */
    const refreshFetch = (resetPage = true): void => {
        let _params: Params = {};
        if (parameter.isPagination) {
            _params  = resetPage ?  Object.assign({}, parameter.params, pagination, { page: 1 }) : Object.assign({}, parameter.params, pagination);
            'total' in _params && delete _params.total;
        } else {
            _params = parameter.params ?? {};
        }
        parameter.fetchApi(_params).then((res: any) => {
            parameter.resTransform && parameter.resTransform(res);
            if ('number' in res && 'size' in res && 'totalElements' in res) {
                Object.assign(pagination, { size: res.size, page: res.number + 1, total: res.totalElements });
            }
            //处理返回的res.content 和 res.data
            if ('content' in res) {
                data.value = res.content;
            } else if ('data' in res) {
                data.value = res.data;
            } else {
                data.value = res;
            }
        })
    }

    onMounted(refreshFetch);

    return {
        pagination,
        data,
        refreshFetch
    };
}

export default useFetchCommon;
