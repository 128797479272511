
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: "Pagination",
        props: {
            total: {
                type: Number,
                default: 0,
                required: true
            },
            pageSizeOptions: {
                type: Array,
            },
            pageSize: {
                type: Number,
                default: 10
            },
            defaultPageSize: {
                type: Number,
                default: 10
            },
            current: {
                type: Number,
                default: 1,
                required: true
            },
            hideOnSinglePage: {
                type: Boolean,
                default: false
            },
            showQuickJumper: {
                type: Boolean,
                default: true
            },
            showSizeChanger: {
                type: Boolean,
                default: true
            }
        },
        emits: ['change', 'show-size-change'],
        setup(props, content) {
            const handlerChange = (pageNumber: number) => {
                content.emit('change', pageNumber);
            }

            const handlerShowSizeChange = (current: number, pageSize: number) => {
                content.emit('show-size-change', current, pageSize);
            }

            return {
                handlerChange,
                handlerShowSizeChange
            }
        }
    })
